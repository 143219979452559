import { useState, useCallback } from "react";
const useToggle = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue((p) => !p);
  }, []);
  return [value, toggle, setValue];
};
export {
  useToggle
};
